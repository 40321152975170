import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const ServicePage = () => (
  <Layout>
    <SEO title="Aginix Services" />
    <h1>Services Page</h1>
  </Layout>
)

export default ServicePage
